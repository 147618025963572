import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/scss/common.scss";
import Landing from "./Views/Pages/Landing/Landing";
import { ParallaxProvider } from "react-scroll-parallax";
import Platform from "./Views/Pages/Platform/Platform";

const App = (props) => {
  return (
    <React.Fragment>
      <ParallaxProvider>
    <BrowserRouter>
      <Routes history={props.history}>
        <Route path='/' element={<Landing />}>
        </Route>
        <Route path='/platform' element={<Platform />}>
        </Route>
      </Routes>
    </BrowserRouter>
    </ParallaxProvider>
  </React.Fragment>
  );
}

export default App;
