import { useState,useEffect } from "react";
import "../../../assets/scss/Loader/Loader.scss";
import logoLg from "../../../assets/images/logoLg.svg";

const Loader = (props) => {

   const [loadingTime, setLoadingTime] = useState(false);
   useEffect(() => {
      // window.addEventListener("load", (event) => {
      //    setTimeout(() => {
      //       setLoadingTime(!loadingTime);            
      //     }, 2000);         
      //  });
      setTimeout(() => {
         setLoadingTime(!loadingTime);            
       }, 6000);
   }, [])
   

   return (
      <div className={`siteLoaderCnt ${loadingTime ?"loaded" : ""}`.trim()}>
         <div className="loaderInnerDiv">
            <div className="d-flex justify-content-center align-items-center loaderCoin">
               <img src={logoLg} width="200" height="200" alt="Loader" className="img-fluid" />
            </div>
            <div className="d-flex justify-content-center align-items-center bnrTx">
               <div className="spinner"></div>
            </div>
         </div>                     
      </div>
   )
}
export default Loader;