import React from 'react';
import { Link, NavLink } from "react-router-dom";
import logo from '../../../assets/images/logo.png';
import "../../../assets/scss/Header/Header.scss";

const Header = (props) => {
   const sbShow = () => {
      document.querySelector('body').classList.toggle('sbMenu');
      document.querySelector('.smClose').classList.toggle('active');
   }
   return (
      <>
         <header className="">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-2">
                     <div className="hdLogo">
                        <Link to="/">
                           <img src={logo} width="171" height="49" alt="logo" />
                        </Link>
                     </div>
                  </div>
                  <div className="col-7">
                     <div className="hdMenuPt">
                        <ul className="hdMenu">
                           <li>
                              <NavLink exact to="/" className={({ isActive }) => (isActive ? 'active' : '')} onClick={sbShow}>Home</NavLink>
                           </li>
                           <li>
                              <Link to="#" onClick={sbShow}>About us</Link>
                           </li>
                           <li>
                              <NavLink to="/platform" className={({ isActive }) => (isActive ? 'active' : '')} onClick={sbShow}>Platform</NavLink>
                           </li>
                           <li>
                              <a href="https://anacoin.tech/" target="_blank"  rel="noreferrer">Anacoin</a>
                           </li>
                           <li>
                              <Link to="#" onClick={sbShow}>NFT Marketplace</Link>
                           </li>
                           <li>
                              <Link to="#" onClick={sbShow}>News</Link>
                           </li>
                           <li className="d-block d-sm-none">
                              <Link to="#" className="btn KnwMrBtn Btn153-45 mr-2" onClick={sbShow}>
                                 Login
                              </Link>
                           </li>
                           <li className="d-block d-sm-none">
                              <Link to="#" className="btn KnwMrBtn Btn153-45" onClick={sbShow}>
                                 Join Now
                              </Link>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-3">
                     <div className="hdRgt">
                        <Link to="#" className="btn KnwMrBtn Btn153-45 mr-2 d-none d-sm-block">
                           Login
                        </Link>
                        <Link to="#" className="btn KnwMrBtn Btn153-45 d-none d-sm-block">
                           Join Now
                        </Link>

                        <div className="mobMenu d-lg-none">
                           <button className="smClose" onClick={sbShow}>
                              <svg id="closeicon" viewBox="0 0 800 600">
                                 <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                 <path d="M300,320 L460,320" id="middle"></path>
                                 <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                              </svg>
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>
      </>
   )
}
export default Header;