import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Ecosystem from "./Ecosystem";
import bannerVideo from '../../../assets/images/bannerVideo.mp4';
import bannerParticles from '../../../assets/images/bannerParticles.gif';
import bannerShadow from '../../../assets/images/bannerShadow.png';
import cryptoLogo from '../../../assets/images/cryptoLogo.png';
import octFinHdIcon1 from '../../../assets/images/oct-fin-hd-icon1.png';
import octFinHdIcon2 from '../../../assets/images/oct-fin-hd-icon2.png';
import octTrnsImg from '../../../assets/images/oct-trns-img.png';
import oct1 from '../../../assets/images/oct1.gif';
import oct2 from '../../../assets/images/oct2.gif';
import oct3 from '../../../assets/images/oct3.gif';
import oct4 from '../../../assets/images/oct4.gif';
import dcrnd1 from '../../../assets/images/dcrnd1.png';
import dcrnd2 from '../../../assets/images/dcrnd2.png';
import dcrnd3 from '../../../assets/images/dcrnd3.png';
import dcrnd4 from '../../../assets/images/dcrnd4.png';
import dcearth from '../../../assets/images/dcearth.png';
import anaspin from '../../../assets/images/anaspin.gif';
import dcprt from '../../../assets/images/dcprt.gif';
import ANACOINIntegration from '../../../assets/images/ANACOINIntegration.gif';
import CommitmentSustainability from '../../../assets/images/CommitmentSustainability.gif';
import ComprehensiveSolutions from '../../../assets/images/ComprehensiveSolutions.gif';
import InnovativeEcosystem from '../../../assets/images/InnovativeEcosystem.gif';

import Loader from "../../Components/Loader/Loader";
import { Parallax } from "react-scroll-parallax";

const Landing = () => {
   return (
         <div className="landingPg">
            <Loader />
            <section className="bannerSec">
             <Header />
             <div className="bannerCnt">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-6">
                        <div className="bnrTx text-center text-lg-left">
                           <h3>
                              Ecosystem Crafted for B2B & B2C Business
                           </h3>
                           <p>OCTAVERSE - The business world virtually leveraging security, transparency and efficiency, through BLOCKCHAIN TECHNOLOGY – the FUTURE.</p>
                           <div className="d-flex justify-content-center justify-content-lg-start align-items-center flex-wrap">
                              <Link to="" className="btn KnwMrBtn Btn153-45 mr-3 my-1">Know More</Link>
                              <Link to="" className="btn KnwMrBtn Btn153-45 my-1">White Paper</Link>
                           </div>
                        </div>
                     </div>
                     
                  </div>
               </div>
               <div className="videoCnt">
                  <video autoPlay={true} muted playsInline loop className="img-fluid">
                     <source src={bannerVideo} type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
                  <img src={bannerParticles} width="1280" height="720" className="particlesImg" alt="particlesImg" />
                  <img src={bannerShadow} width="878" height="808" className="particlesShadow" alt="particlesShadow" />
               </div>

             </div>
            </section>
            <section className="OctFnancSec">
               <div className="container">
                     <div className="row justify-content-between">
                        <div className="col-lg-5 mb-3 mb-lg-0 d-flex">
                           <div className="OctFnancBxTrngle w-100 d-flex flex-column">
                                 <div className="OctFnancBx OctFnancBx1">
                                    <div className="OctFnancBxHdd mb-3">
                                    <Parallax speed={3}>
                                       <h4><span><img src={octFinHdIcon1} alt="#" height="40" className="mr-2" /></span>anapay</h4>
                                       </Parallax>
                                    </div>
                                    <div className="OctFnancBxCnt">
                                       <p>ANAPAY is the future of decentralized, scalable cryptocurrency payment tailored for E-Commerce and merchant transactions. ANAPAY incorporates a protocol to enable users to pay daily expenditures using ANACOIN.</p>
                                       <p>ANACOIN, a BEP-20 token is envisioned as a utility token within the ANAPAY ecosystem facilitating cryptocurrency payments, which will be efficient for investors, consumers, merchants, and businesses without physical interaction at the point of sale with shared devices.</p>
                                       <p>ANAPAY has an inbuilt participate application that supports Octaverse community development and acts as a bridge for seamless transactions with ANACOIN within the Octaverse ecosystem.</p>
                                    </div>
                                    <div className="text-right mt-auto">
                                       <button className="btn KnwMrBtn Btn153-45" type="button">Know More </button>
                                    </div>
                                 </div>
                           </div>
                        </div>
                        <div className="col-lg-5 mb-3 mb-lg-0 d-flex">
                           <div className="OctFnancBx OctFnancBx2 w-100 d-flex flex-column">
                                 <div className="OctFnancBxHdd mb-3">
                                    <Parallax speed={3}>
                                       <h4><span><img src={octFinHdIcon2} alt="#" height="40" className="mr-2" /></span>anawallet</h4>
                                    </Parallax>
                                 </div>
                                 <div className="OctFnancBxCnt">
                                    <p>ANAWALLET, a decentralized wallet offering a user-friendly interface, robust P2P feature, and advanced security measures to protect your digital assets. With multi-currency support, it simplifies asset management and includes a built-in DApp browser for seamless interaction with decentralized applications, thereby fostering the growth and value of assets within the ecosystem. ANAWALLET integrates with ANAPAY for easy and instant transactions within the ecosystem and decentralized exchanges (DEXs) for secure and efficient trading through biometric authentication and hardware wallet integration further enhancing asset protection.</p>
                                 </div>
                                 <div className="text-right mt-auto">
                                    <button className="btn KnwMrBtn Btn153-45" type="button">Know More </button>
                                 </div>
                           </div>
                        </div>
                     </div>
               </div>
            </section>
            <section className="OctTrnsfrSec">
               {/* <div className="OctTrnsBnrCnt">
                  <div className="OctTrnsImg">
                        <img src={octTrnsImg} alt="" className="img-fluid d-block mx-auto" />
                  </div>
               </div> */}
               <div className="container">
                     <div className="row justify-content-end">
                        <div className="col-xl-6 d-flex justify-content-center align-items-center px-0">
                           <div className="OctTrnsImg">
                              <Parallax speed={3}>
                                 <img src={octTrnsImg} alt="" className="img-fluid d-block mx-auto" />
                              </Parallax>
                           </div>
                        </div>
                        <div className="col-xl-6 d-flex align-items-center">
                           <div className="w-100">
                              <div className="OctTrnsCnt">
                                 <Parallax speed={5}>
                                 <h4 className="mb-3">Fast, Cheap, and reliable transactions, no matter the value of the Transfers</h4>
                                 </Parallax>
                                 <p>The Octaverse project encompasses various fields and applications, leveraging blockchain technology and cryptocurrency. Octaverse supports payment solutions, merchant integration, Decentralized Finance (DeFi), cryptocurrency investments,
                                    supply chain and stock management, record keeping, ledger maintenance, voting systems, Decentralized Autonomous Organizations (DAOs), identity protection, digital payment adoption, and interoperability, allowing its blockchain
                                    to interact seamlessly with existing systems, networks, and technologies. These applications demonstrate the versatility of the Octaverse project across various sectors, offering solutions for both businesses and individuals
                                    in the rapidly evolving cryptocurrency and blockchain landscape.</p>
                              </div>
                              <div className="">
                                    <button className="btn KnwMrBtn Btn153-45" type="button">Read More </button>
                              </div>
                           </div>                           
                        </div>
                     </div>
               </div>
            </section>

            <section className="ecosystemCnt">
               <div className="container">
                  <Parallax speed={5}>
                     <h3 className="secHeading text-center">Octaverse Ecosystem</h3>
                  </Parallax>
                  <div className="w-100 d-flex justify-content-center">
                     <Ecosystem />
                  </div>                  
               </div>
            </section>

            <section className="whySec">
               <div className="container">
                     <Parallax speed={5}>
                        <h2 className="oct_common_hd text-center ndmb-50">WHAT IS OCTAVERSE</h2>
                     </Parallax>
                     <div className="row nd_row">
                        <div className="col-xl-3 col-lg-6 col-sm-6 nd-col d-flex">
                           <div className="Card_oct">
                                 <div className="img_1">
                                    <img className="img-fluid" src={InnovativeEcosystem} width="" height="" alt="" />
                                 </div>
                                 <h2>Innovative Ecosystem</h2>
                                 <p>Octaverse is a visionary platform driving innovation across eight strategic sectors, leveraging cutting-edge technology and strategic partnerships to transform industries, providing Solution for building a real time ecosystem.</p>
                           </div>

                        </div>
                        <div className="col-xl-3 col-lg-6 col-sm-6 nd-col d-flex">
                           <div className="Card_oct">
                                 <div className="img_2">
                                    <img className="img-fluid" src={ANACOINIntegration} width="" height="" alt="" />
                                 </div>
                                 <h2>ANACOIN Integration</h2>
                                 <p>At the heart of Octaverse is ANACOIN, a versatile cryptocurrency facilitating seamless transactions and fostering engagement within the ecosystem.</p>
                           </div>

                        </div>
                        <div className="col-xl-3 col-lg-6 col-sm-6 nd-col d-flex">
                           <div className="Card_oct">
                                 <div className="img_3">
                                    <img className="img-fluid" src={oct2} width="" height="" alt="" />
                                 </div>
                                 <h2>Commitment to Sustainability</h2>
                                 <p>Octaverse prioritizes sustainable development by ensuring its initiatives positively impact long-term growth and stability through advanced crypto asset management and digital ownership via NFTs. This approach supports both individual users and the broader ecosystem.</p>
                           </div>

                        </div>
                        <div className="col-xl-3 col-lg-6 col-sm-6 nd-col d-flex">
                           <div className="Card_oct">
                                 <div className="img_4">
                                    <img className="img-fluid" src={ComprehensiveSolutions} width="" height="" alt="" />
                                 </div>
                                 <h2>Comprehensive Solutions</h2>
                                 <p>Octaverse offers specialized platforms like ANAPAY and ANAWALLET for enhancing digital payments, and financial fusion with blockchain integration for easy, secured, and efficient transactions.</p>
                           </div>

                        </div>
                     </div>
               </div>
            </section>
            <section className="DCE_sec">
               <div className="container position-relative">
                     <Parallax speed={5}>
                        <h2 className="oct_common_hd  ndmb-50">Digital Currency<br /> <span>within our</span> Ecosystem </h2>
                     </Parallax>
                     <div className="earth_chart">
                        <div className="chart_cnt list_1">
                           <div className="ndml-29">
                                 <h2>Decimals</h2>
                                 <h3>18</h3>
                           </div>
                           <img src={dcrnd1} alt="" />
                        </div>
                        <div className="chart_cnt list_2">
                           <div className="ndml-29">
                                 <h2>total supply</h2>
                                 <h3> 1 Billion</h3>
                           </div>
                           <img src={dcrnd2} alt="" />
                        </div>
                        <div className="chart_cnt list_3">
                           <div className="ndml-29">
                                 <h2>token name</h2>
                                 <h3>ANACOIN</h3>
                           </div>
                           <img src={dcrnd3} alt="" />
                        </div>
                        <div className="chart_cnt list_4">
                           <div className="ndml-29">
                                 <h2>Ticker</h2>
                                 <h3>ANA</h3>
                           </div>
                           <img src={dcrnd4} alt="" />
                        </div>
                        <img className="earthimg" src={dcearth} alt="" />

                     </div>
                     <img className="octspin_cnt2" src={anaspin} alt="" />

               </div>
               <img className="part_cnt" src={dcprt} alt="" />
               <img className="part_cnt2" src={dcprt} alt="" />
            </section>

            <section className="roadmapSec">
               <div className="container">
                  <Parallax speed={5}>
                     <h3 className="secHeading text-center">Roadmap</h3>
                  </Parallax>
                  <div className="roadMapContainer">
                     <div className="roadmapRow row1">
                        <div className="roadmapCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q1 - Q2 2023</h3>
                              <ul>
                                 <li>Market Research & Feasibility Study</li>
                                 <li>Project Inception</li>
                                 <li>Management Team Development</li>
                                 <li>Legal & Regulatory Compliance</li>
                              </ul>
                           </div>                        
                        </div>
                        <div className="roadmapCnt rightCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q3 - Q4 2023</h3>
                              <ul>
                                 <li>Hiring Talent</li>
                                 <li>Octaverse Official Website Development</li>
                                 <li>ANACOIN Official Website Development</li>
                                 <li>ANACOIN Whitepaper Development</li>
                                 <li>ANACOIN Smart Contract Development & Token Creation</li>
                                 <li>Pre-Marketing</li>
                                 <li>Branding</li>
                              </ul>
                           </div>                        
                        </div>
                     </div>
                     <div className="roadmapRow row2">
                        <div className="roadmapCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q1 - Q2 2024</h3>
                              <ul>
                                 <li>Strategic Partnerships</li>
                                 <li>ANACOIN Internal Testing & Token Audit</li>
                                 <li>Octaverse Official Website Launch</li>
                                 <li>ANACOIN Official Website Launch</li>
                                 <li>Publishing ANACOIN Whitepaper</li>
                              </ul>
                           </div>                        
                        </div>
                        <div className="roadmapCnt rightCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q3 2024</h3>
                              <ul>
                                 <li>ANACOIN Private Sale & Whitelisting</li>
                                 <li>ICO Development</li>
                                 <li>ANACOIN Airdrop Partnership</li>
                                 <li>Marketing Campaigns</li>
                              </ul>
                           </div>                        
                        </div>
                     </div>
                     <div className="roadmapRow row3">
                        <div className="roadmapCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q4 2024</h3>
                              <ul>
                                 <li>ANACOIN Listing on Tier 2 Exchanges</li>
                                 <li>ANACOIN Launch on CoinMarketCap & CoinGecko</li>
                                 <li>ICO Launch</li>
                                 <li>ANACOIN Staking and Token Distribution</li>
                                 <li>ANAPAY Development</li>
                              </ul>
                           </div>                        
                        </div>
                        <div className="roadmapCnt rightCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q1 - Q2 2025</h3>
                              <ul>
                                 <li>Marketing & Outreach Campaigns</li>
                                 <li>Community Building</li>
                                 <li>ANAWALLET Development</li>
                              </ul>
                           </div>                        
                        </div>
                     </div>
                     <div className="roadmapRow row4">
                        <div className="roadmapCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q3 2025</h3>
                              <ul>
                                 <li>ANAPAY & ANAWALLET Launch</li>
                                 <li>Blockchain Development</li>
                                 <li>DEX Development</li>
                                 <li>Native Coin & NFT Development</li>
                              </ul>
                           </div>                        
                        </div>
                        <div className="roadmapCnt rightCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q4 2025</h3>
                              <ul>
                                 <li>ANACOIN Listing on DEX</li>
                                 <li>Integration with DApps</li>
                                 <li>Blockchain Launch</li>
                                 <li>Native Coin & NFT Launch</li>
                                 <li>NFT Marketplace Launch</li>
                                 <li>ANAPAY & ANAWALLET Integration with Octaverse Ecosystem</li>
                              </ul>
                           </div>                        
                        </div>
                     </div>
                     <div className="roadmapRow row5">
                        <div className="roadmapCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q1 - Q2 2026</h3>
                              <ul>
                                 <li>ANACOIN Listing on Tier 1 Exchanges</li>
                                 <li>Innovation and R&D</li>
                                 <li>ANAPAY - E-commerce and POS Terminal Development</li>
                                 <li>Beta Testing</li>
                              </ul>
                           </div>                        
                        </div>
                        <div className="roadmapCnt rightCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q3 - Q4 2026</h3>
                              <ul>
                                 <li>Strategic Partnerships Expansion</li>
                                 <li>ANAWALLET Integration – E-commerce and POS</li>
                                 <li>ANAPAY Integration - E-commerce and POS</li>
                                 <li>ANAPAY - E-commerce and POS Terminal Product Release</li>
                              </ul>
                           </div>                        
                        </div>
                     </div>
                     <div className="roadmapRow row6">
                        <div className="roadmapCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q1 - Q4 2027</h3>
                              <ul>
                                 <li>Community & Ecosystem growth</li>
                                 <li>Global Outreach</li>
                                 <li>Initiate Project Development & Integration – Octaverse</li>
                              </ul>
                           </div>                        
                        </div>
                        <div className="roadmapCnt rightCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q1 2028</h3>
                              <ul>
                                 <li>Initiate ANACOIN & Native Coin Burning Process</li>
                              </ul>
                           </div>                        
                        </div>
                     </div>
                     <div className="roadmapRow row7">
                        <div className="roadmapCnt">
                           <div className="roadmapInnerCnt">
                              <h3 className="heading">Q1 2029</h3>
                              <ul>
                                 <li>Sustainability and Scalability</li>
                              </ul>
                           </div>                        
                        </div>
                     </div>
                     <div className="roadmapRow row8">
                        <div className="roadmapCnt">
                           <div className="roadmapInnerCnt text-center">
                              <p className="my-2">2030 & Beyond</p>
                              <h3 className="heading mb-0 uppercase">EVOLVING TO BECOME A GLOBAL UTILITY PROJECT</h3>
                           </div>                        
                        </div>
                     </div>
                  </div>
               </div>
            </section>

            <section className="faqSec">
               <div className="container">
                  <Parallax speed={5}>
                     <h3 className="secHeading text-center">FREQUENTLY ASKED QUESTIONS (FAQ)</h3>
                  </Parallax>
                  <div className="w-100">
                     <Accordion transition transitionTimeout={250}>
                        <AccordionItem header="What is Octaverse?" initialEntered>
                           <p>Octaverse is a pioneering platform dedicated to advancing innovation and sustainability across eight strategic sectors: Blockchain, E-Commerce, Retail Chain, Infrastructure, Fintech, Energy Tech, Agro, and Wellness. The ecosystem, seamlessly integrated with ANAPAY and ANAWALLET, leverages strategic partnerships to redefine industries. By delivering comprehensive solutions and fostering a real-time ecosystem, Octaverse is committed to promoting holistic well-being and driving progress across diverse sectors.</p>
                        </AccordionItem>

                        <AccordionItem header="How does ANACOIN function within the Octaverse ecosystem?">
                           <p>ANACOIN, a BEP-20 token, functions as a utility token within the Octaverse ecosystem. It enables seamless and efficient transactions, facilitating payments and interactions across diverse sectors, including E-Commerce, Retail Chain, and Blockchain applications. Community can utilize ANACOIN for purchases, receive discounts, and participate in exclusive programs across various sectors, promoting widespread adoption, utility, and engagement. By supporting advanced payment solutions such as ANAPAY and ANAWALLET, as well as decentralized exchanges (DEXs), ANACOIN enhances secure cryptocurrency management and addresses market challenges effectively.</p>
                        </AccordionItem>

                        <AccordionItem header="How does OCTAVERSE stand out from other platforms?">
                        <p>Octaverse is a community-driven ecosystem that harnesses blockchain technology, cryptocurrency assets, and NFTs across its eight sectors. It differentiates itself from other platforms by fostering growth through active community engagement and providing incentives for participation, thereby evolving in tandem with its community.</p>
                        </AccordionItem>
                        
                        <AccordionItem header="What advantages does OCTAVERSE offer?">
                           <p><span className="boldTxt whiteTxt">Innovative Ecosystem Integration:</span> Octaverse integrates cutting-edge technology and strategic partnerships across eight sectors, enhancing industry transformation and creating a comprehensive, interconnected platform.</p>
                           <p><span className="boldTxt whiteTxt">Enhanced Financial Solutions:</span> With platforms like ANAPAY and ANAWALLET, Octaverse provides advanced payment solutions and secure cryptocurrency management, facilitating efficient and seamless transactions.</p>
                           <p><span className="boldTxt whiteTxt">Sustainability and Growth:</span> The platform prioritizes sustainable development, promoting eco-friendly practices and long-term stability through strategic initiatives, including digital asset management and NFT integration.</p>
                           <p><span className="boldTxt whiteTxt">Community-Driven Incentives:</span> Octaverse fosters community engagement by offering incentives for participation, ensuring that growth and success are aligned with the active involvement of its user base.</p>
                        </AccordionItem>
                     </Accordion>
                  </div>
               </div>
            </section>

            {/* <section className="publicationSec">
               <div className="container">
                  <Parallax speed={5}>
                     <h3 className="secHeading text-center">Crypto Publications we appear in</h3>
                  </Parallax>
                  <div className="row">
                     <div className="col-md-6 col-lg-3">
                        <div className="publicationCnt">
                           <img src={cryptoLogo} width="165" height="53" alt="cryptoLogo" className="img-fluid" />
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-3">
                        <div className="publicationCnt">
                           <img src={cryptoLogo} width="165" height="53" alt="cryptoLogo" className="img-fluid" />
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-3">
                        <div className="publicationCnt">
                           <img src={cryptoLogo} width="165" height="53" alt="cryptoLogo" className="img-fluid" />
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-3">
                        <div className="publicationCnt">
                           <img src={cryptoLogo} width="165" height="53" alt="cryptoLogo" className="img-fluid" />
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-3">
                        <div className="publicationCnt">
                           <img src={cryptoLogo} width="165" height="53" alt="cryptoLogo" className="img-fluid" />
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-3">
                        <div className="publicationCnt">
                           <img src={cryptoLogo} width="165" height="53" alt="cryptoLogo" className="img-fluid" />
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-3">
                        <div className="publicationCnt">
                           <img src={cryptoLogo} width="165" height="53" alt="cryptoLogo" className="img-fluid" />
                        </div>
                     </div>
                     <div className="col-md-6 col-lg-3">
                        <div className="publicationCnt">
                           <img src={cryptoLogo} width="165" height="53" alt="cryptoLogo" className="img-fluid" />
                        </div>
                     </div>
                  </div>
               </div>
            </section> */}

            <section className="touchSec">
               <div className="container">
                  <Parallax speed={5}>
                     <h3 className="secHeading text-center">Get in to touch</h3>
                  </Parallax>
                  <div className="row justify-content-center">
                     <div className="col-lg-6">
                        <div className="touchCnt">
                           <form>
                              <div className="form-group">
                                 <label className="formLabel" htmlFor="name1">Name</label>
                                 <input type="text" className="form-control" id="name1" placeholder="Enter Your Name"/>
                              </div>
                              <div className="form-group">
                                 <label className="formLabel" htmlFor="email1">Email</label>
                                 <input type="text" className="form-control" id="email1" placeholder="Enter Your Email"/>
                              </div>
                              <div className="form-group">
                                 <label className="formLabel" htmlFor="msg1">Message</label>
                                 <textarea className="form-control" placeholder="Message" id="msg1"></textarea>
                              </div>
                              <div className="form-group d-flex justify-content-center">
                                 <button className="btn KnwMrBtn Btn153-45" type="button">Submit</button>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <Footer />
         </div>
   )
}
export default Landing;