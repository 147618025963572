import { Link } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import lgLogo from '../../../assets/images/logo.svg';
import goldLogo from '../../../assets/images/goldLogo.png';
import platformBlockchainBanner from '../../../assets/images/platformBlockchainBanner.png';
import platformWellnessBanner from '../../../assets/images/platformWellnessBanner.png';
import platformRetailChainBanner from '../../../assets/images/platformRetailChainBanner.png';
import platformEcommerceBanner from '../../../assets/images/platformEcommerceBanner.png';
import platformInfrastructureBanner from '../../../assets/images/platformInfrastructureBanner.png';
import platformFintechBanner from '../../../assets/images/platformFintechBanner.png';
import platformAgrotechBanner from '../../../assets/images/platformAgrotechBanner.png';
import platformEnergytechBanner from '../../../assets/images/platformEnergytechBanner.png';

const Platform = () => {
   return (
      <div className="platformSec">
         <section>
            <Header />
            <div className="container">
               <div className="row justify-content-center">
                  <div className="col-lg-10">
                     <div className="logoCard">
                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                           <img src={lgLogo} className="img-fluid" />
                           <img src={goldLogo} className="img-fluid" />
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row mb-3">
                  <div className="col-12">
                     <div className="platformCardCnt">
                        <div className="row align-items-center">
                           <div className="col-lg-6">
                              <h2 className="oct_common_hd">Blockchain</h2>
                              <p>OCTAVERSE is leveraging blockchain technology to introduce innovative platforms that enhance transparency, security, and efficiency across various sectors. OCTAVERSE’s introduction of OCTAGON, ANAWALLET, ANASWAP and ANAPAY demonstrates its commitment to harnessing blockchain technology for secure, transparent, and efficient digital transactions. These platforms pave the way for the widespread adoption of cryptocurrencies and decentralized applications, driving forward the evolution of the digital economy across global markets.</p>
                           </div>
                           <div className="col-lg-6 pfrmBannerCnt">
                              <img src={platformBlockchainBanner} className="img-fluid pfrmBanner" />
                           </div>                           
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">OCTAGON Blockchain Platform</h3>
                     <p>OCTAGON is a robust blockchain platform designed to support decentralized applications (dApps) and manage crypto assets. This platform facilitates the development and deployment of smart contracts, enabling secure and efficient transactions within the OCTAVERSE ecosystem</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">ANAWALLET</h3>
                     <p>ANAWALLET is a decentralized crypto wallet integrated within OCTAVERSE. It empowers users by providing a secure and transparent platform for managing cryptocurrencies and conducting transactions. ANAWALLET enhances trust among users by ensuring the integrity and confidentiality of digital transactions.</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">ANASWAP</h3>
                     <p>ANASWAP is a decentralized exchange (DEX) developed by OCTAVERSE to facilitate swapping options for the community and enhance liquidity within the OCTAVERSE ecosystem by allowing seamless token exchanges. It enables users to swap various cryptocurrencies directly without relying on a centralized exchange, providing a secure and transparent environment for trading digital assets. ANASWAP supports a wide range of tokens, including ANACOIN, thereby promoting its use and adoption. Additionally, it integrates with ANAWALLET for easy and secure access to trading functionalities.</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">ANAPAY</h3>
                     <p>ANAPAY is a semi-decentralized platform within the OCTAVERSE ecosystem that supports a wide range of applications, particularly focused on payment solutions in the ecommerce and retail sectors. This platform facilitates seamless transactions using cryptocurrencies like ANACOIN, promoting adoption and efficiency in digital payments, which also has an inbuilt application that supports community development within the Octaverse Platform.</p>
                  </div>
                  <div className="col-12">
                     <div className="platformCardCnt">
                        <div className="row align-items-center">
                           <div className="col-lg-5 d-flex justify-content-center pfrmBannerCnt order-2 order-lg-1">
                              <img src={platformWellnessBanner} className="img-fluid pfrmBanner" />
                           </div>
                           <div className="col-lg-6 order-1 order-lg-2">
                              <h2 className="oct_common_hd">WELLNESS</h2>
                              <p>Wellness is indeed considered a fundamental right and an essential aspiration for everyone. OCTAVERSE has forged a strategic partnership with NUWAY, to be a visionary force aiming to revolutionize the wellness ecosystem. This collaboration underscores OCTAVERSE's commitment to promoting holistic well-being of various dimensions of Wellness, such as Physical. Financial, Social, Emotional, Spiritual, Cultural, Intellectual, and Environmental Wellness through innovative solutions and initiatives to enhance the quality of life for people worldwide</p>
                           </div>                           
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12">
                     <p>The OCTAVERSE-NUWAY partnership represents a strategic alliance dedicated to advancing holistic wellness through innovation, community engagement, and global outreach. By integrating ANACOIN tokens to incentivize community participation and adoption towards wellness and well-being, the partnership strives to make a positive impact on individuals' lives worldwide</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Holistic Well-being</h3>
                     <p>By partnering with NUWAY, OCTAVERSE emphasizes its dedication to enhancing various aspects of wellness, including Physical, Financial, Social, Emotional, Spiritual, Cultural, Intellectual, and Environmental wellness. This holistic approach suggests a broad spectrum of initiatives aimed at improving overall quality of life.</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Innovative Solutions and Initiatives</h3>
                     <p>The collaboration suggests a focus on introducing groundbreaking solutions that integrate technology with wellness principles. This could include digital platforms, apps, wearable devices, and other tools designed to support wellness across multiple domains</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">ANACOIN Token Allocation</h3>
                     <p>Allocating 100 million ANACOIN tokens for an exclusive Airdrop program underscores OCTAVERSE’s intent to incentivize and engage its community. ANACOIN tokens could potentially be used within the ecosystem for rewards, transactions, or accessing wellness-related services.</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Global Adoption and Community Engagement</h3>
                     <p>While starting in India, the partnership aims to foster widespread adoption globally. This suggests scalability and ambition to impact wellness on a global scale, leveraging NUWAY’s expertise and OCTAVERSE’s reach.</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Partnership Benefits</h3>
                     <p>Collaborating with NUWAY likely brings mutual benefits such as shared resources, expertise, and market access. It also enhances credibility by associating with NUWAY’s reputation and vision in the wellness sector</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Future Prospects</h3>
                     <p>Looking ahead, the partnership could lead to joint ventures, cobranded initiatives, or further integration of wellness-focused technologies and services within OCTAVERSE’s ecosystem.</p>
                  </div>
                  <div className="col-lg-12">
                     <p>Overall, the OCTAVERSE-NUWAY partnership represents a strategic alignment towards advancing holistic wellness through innovative approaches, community engagement, and global expansion, underpinned by the allocation of ANACOIN tokens for incentivizing participation and adoption.</p>
                  </div>
                  <div className="col-12">
                     <div className="platformCardCnt">
                        <div className="row align-items-center">
                           <div className="col-lg-8">
                              <h2 className="oct_common_hd">RETAIL CHAINS</h2>
                              <p>OCTAVERSE is embracing a diverse spectrum of retail industries, encompassing chains of supermarkets, restaurants, pharmacy chains, hospital chains, convenience stores, hotels, motels, and resorts. Octaverse by integrating ANACOIN, through ANAPAY as a preferred mode of payment within this ecosystem, strengthens its position in the evolving landscape of digital payments also Octaverse aims to achieve several key objectives</p>
                           </div>
                           <div className="col-lg-3 d-flex justify-content-center pfrmBannerCnt">
                              <img src={platformRetailChainBanner} className="img-fluid pfrmBanner" />                              
                           </div>                           
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Promoting Adoption</h3>
                     <p>By encouraging the use of ANACOIN for transactions across various retail sectors, OCTAVERSE facilitates widespread adoption of its digital currency. This aligns with the broader trend towards digital payments and enhances convenience for users.</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Exclusive Discounts and Incentives</h3>
                     <p>Users who choose to transact with ANACOIN will enjoy exclusive discounts and incentives. This strategy not only incentivizes customers to use ANACOIN but also rewards loyalty within the OCTAVERSE ecosystem</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Fostering Innovation</h3>
                     <p>OCTAVERSE's commitment to innovative payment solutions is evident through the integration of ANACOIN. This initiative supports the development of a vibrant ecosystem where retail partners and customers alike benefit from efficient and secure digital transactions</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Enhancing Customer Experience</h3>
                     <p>Using ANACOIN for payments enhances the overall customer experience by providing a seamless, cashless transaction process. It simplifies transactions across different retail sectors under OCTAVERSE, promoting user satisfaction and loyalty</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Supporting Retail Partners</h3>
                     <p>Retail partners within OCTAVERSE benefit from increased customer engagement and loyalty due to the attractive incentives offered through ANACOIN transactions. This partnership strengthens relationships and fosters a collaborative environment focused on mutual growth</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Scalability and Global Reach</h3>
                     <p>As OCTAVERSE expands globally, the integration of ANACOIN as a preferred payment method positions the ecosystem to scale efficiently across diverse markets and industries, further enhancing its relevance and impact</p>
                  </div>
                  <div className="col-12">
                     <div className="platformCardCnt">
                        <div className="row align-items-center">
                           <div className="col-lg-6 d-flex justify-content-center pfrmBannerCnt order-2 order-lg-1">
                              <img src={platformEcommerceBanner} className="img-fluid pfrmBanner blendLight" />
                           </div>
                           <div className="col-lg-6 order-1 order-lg-2">
                              <h2 className="oct_common_hd">E-COMMERCE</h2>
                              <p>At OCTAVERSE, we serve as a comprehensive B2B & B2C vendor management platform, providing a unified marketplace where vendors can showcase their products and services. Integration of ANACOIN and ANAPAY within the ecosystem, we simplify transactions while upholding security and transparency, fostering a digital economy where users can transact confidently and seamlessly. This integrated ecosystem offers numerous benefits for both businesses and customers</p>
                           </div>                           
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Unified Marketplace</h3>
                     <p>Vendors within OCTAVERSE can effectively showcase their offerings to a wide audience of businesses (B2B) and consumers (B2C). This centralized platform streamlines the process of discovering and purchasing products and services across various vendor websites.</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Seamless Experience</h3>
                     <p>Businesses and customers benefit from a seamless shopping and business experience. They can browse and compare offerings from different vendors within the OCTAVERSE platform, enhancing convenience and efficiency in procurement and shopping processes</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">ANACOIN Integration</h3>
                     <p>The integration of ANACOIN as a transactional currency provides flexibility and convenience. Businesses and customers can use ANACOIN to facilitate transactions, simplifying payment processes and reducing friction associated with traditional payment methods</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Enhanced Security and Transparency</h3>
                     <p>Blockchain technology underpins ANACOIN transactions, ensuring enhanced security and transparency. This aligns with OCTAVERSE's commitment to fostering a digital economy where trust and integrity are paramount.</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Business Growth Opportunities</h3>
                     <p>Vendors benefit from increased visibility and access to a diverse customer base within OCTAVERSE. This exposure can lead to business growth opportunities and partnerships, further expanding their market reach and enhancing profitability</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Support for Innovation</h3>
                     <p>OCTAVERSE encourages innovation among vendors by providing a platform that supports digital transformation and advanced technological solutions. This enables vendors to adopt cutting-edge practices that meet the evolving needs of businesses and consumers</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Scalability and Adaptability</h3>
                     <p>Our platform is designed to scale with the growing needs of vendors and customers. It adapts to technological advancements and market changes, ensuring that users continue to receive innovative solutions and reliable services ensuring long-term relevance and competitiveness</p>
                  </div>
                  <div className="col-12">
                     <div className="platformCardCnt">
                        <div className="row align-items-center">
                           <div className="col-lg-7">
                              <h2 className="oct_common_hd">INFRASTRUCTURE</h2>
                              <p>Octaverse is committed to advancing efficiency, resilience, and environmental sustainability across real estate and infrastructure projects through strategic investments and collaborative ventures, shaping future landscapes with a focus on long-term social and economic impact, and insight into urban and rural development.</p>
                              <p>Further, Octaverse Community's participation in investing in infrastructure and real estate projects through NFTs (Non-Fungible Tokens) is innovative and potentially transformative, which allows some key aspects and benefits below</p>
                           </div>
                           <div className="col-lg-5 d-flex justify-content-center pfrmBannerCnt">
                              <img src={platformInfrastructureBanner} className="img-fluid pfrmBanner blendLight" />                              
                           </div>                           
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Decentralized Investment Opportunity</h3>
                     <p>By tokenizing investment opportunities through NFTs, you democratize access to traditionally exclusive sectors like real estate and infrastructure projects. This allows a broader range of individuals to participate in investment activities</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Increased Liquidity</h3>
                     <p>NFTs can provide fractional ownership, allowing investors to buy and sell smaller portions of assets, thus increasing liquidity compared to traditional real estate investments.</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Transparency and Security</h3>
                     <p>Blockchain technology, on which NFTs are based, offers enhanced transparency and security through immutable records and smart contracts. This can build trust among investors and reduce transaction costs</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">ROI and Rewards</h3>
                     <p>Investors participating through NFTs can potentially earn returns on their investments (ROI) through rental income, property appreciation, or dividends from infrastructure projects. Additionally, rewards such as exclusive access, discounts, or additional NFTs can incentivize and reward early adopters and active participants.</p>
                  </div>
                  <div className="col-lg-12">
                     <h3 className="oct_common_sunHeading">Community Engagement</h3>
                     <p>Engaging the Octaverse Community in investment decisions fosters a sense of ownership and involvement. Community feedback and votes could influence project choices or operational decisions, enhancing community engagement and loyalty</p>
                  </div>
                  <div className="col-12">
                     <div className="platformCardCnt">
                        <div className="row align-items-center">
                           <div className="col-lg-5 d-flex justify-content-center pfrmBannerCnt order-2 order-lg-1">
                              <img src={platformFintechBanner} className="img-fluid pfrmBanner blendLight" />
                           </div>
                           <div className="col-lg-7 order-1 order-lg-2">
                              <h2 className="oct_common_hd">FINTECH</h2>
                              <p>In the dynamic landscape of financial technology, OCTAVERSE is at the forefront of leveraging blockchain innovation and peer-to-peer transactions to revolutionize digital finance. At its core is ANAWALLET, a decentralized crypto wallet designed to provide users with secure, efficient, and transparent financial services. This platform is complemented by ANAPAY, a semi-decentralized platform that facilitates connectivity between community and businesses</p>
                           </div>                           
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12">
                     <p>ANAPAY features a Participate program specifically tailored to incentivize community building and promote ANACOIN and other cryptocurrencies within the ecosystem. This program is designed to foster active participation and engagement among community members while also supporting the development of young projects through fundraising initiatives. Participants have the opportunity to earn rewards by undertaking various activities such as promoting ANACOIN adoption, advocating for the ecosystem's benefits, and actively contributing to community growth and sustainable growth of the ecosystem</p>
                     <p>The Participate program serves dual purposes: first, to enhance the visibility and adoption of ANACOIN and associated cryptocurrencies by leveraging community advocacy and involvement. Second, it supports budding projects within the ecosystem by providing a platform for fundraising and development. The staking option with ANAPAY is a major tool ensuring long-term community support required for our business and crypto projects within our Octaverse ecosystem while giving out rewards to its Token holders in exchange for supporting and believing in the Projects within our Octaverse ecosystem.</p>
                     <p>Looking forward, OCTAVERSE is poised to expand its influence by integrating with traditional financial systems, including Banking, Insurance companies, and Investment firms. This strategic expansion aims to enhance the accessibility and inclusivity of digital finance solutions globally.</p>
                     <p>By bridging the gap between traditional finance and decentralized technologies, OCTAVERSE not only pioneers’ advancements in financial services but also empowers individuals and businesses to participate more actively in the evolving digital economy. This vision underscores OCTAVERSE’s commitment to driving positive change and fostering innovation in the financial technology sector</p>
                  </div>
                  <div className="col-12">
                     <div className="platformCardCnt">
                        <div className="row align-items-center">
                           <div className="col-lg-8">
                              <h2 className="oct_common_hd">AGROTECH</h2>
                              <p>Agriculture forms the backbone of an economy, providing essential sustenance, employment, and economic stability." In the agricultural domain, OCTAVERSE pioneers innovative solutions to optimize productivity and sustainability. By integrating cutting-edge technologies and fostering strategic partnerships, OCTAVERSE seeks to revolutionize agricultural practices while ensuring food security and environmental stewardship. This commitment underscores OCTAVERSE's dedication to supporting global agricultural ecosystems and promoting sustainable farming practices, thereby advancing economic resilience and food sovereignty worldwide.</p>
                           </div>
                           <div className="col-lg-4 d-flex justify-content-center pfrmBannerCnt">
                              <img src={platformAgrotechBanner} className="img-fluid pfrmBanner blendLight" />                              
                           </div>                           
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12">
                     <p>Moreover, the manufacturing industry within the agricultural sector plays a crucial role in producing value-added products. By producing value-added goods such as processed foods, textiles, and biofuels, the Manufacturing industry enhances profitability for farmers and</p>
                     <p>Moreover, the manufacturing industry within the agricultural sector plays a crucial role in producing value-added products. By producing value-added goods such as processed foods, textiles, and biofuels, the Manufacturing industry enhances profitability for farmers and creates new employment opportunities along the agricultural value chain. OCTAVERSE aims to facilitate this integration and growth, contributing to economic development and resilience in agricultural communities worldwide.</p>
                     <p>Octaverse actively engages the community in investing in the agro and manufacturing sectors through Non-Fungible Tokens (NFTs), allowing participants to earn rewards and achieve Return on Investment (ROI). This innovative approach enables individuals to acquire NFTs representing investments in agricultural and manufacturing projects. As these projects generate returns, NFT holders benefit from rewards based on their investment participation. This innovative method democratizes investment opportunities, allowing individuals to participate in sectors traditionally dominated by larger institutions. By leveraging NFTs, Octaverse not only promotes financial inclusivity but also encourages community engagement and economic development in vital industries like agriculture and manufacturing. This approach aligns with Octaverse's commitment to innovation, sustainability, and fostering a robust and participatory economic ecosystem.</p>
                  </div>
                  <div className="col-12">
                     <div className="platformCardCnt">
                        <div className="row align-items-center">
                           <div className="col-lg-5 d-flex justify-content-center pfrmBannerCnt order-2 order-lg-1">
                              <img src={platformEnergytechBanner} className="img-fluid pfrmBanner blendLight" />
                           </div>
                           <div className="col-lg-7 order-1 order-lg-2">
                              <h2 className="oct_common_hd">ENERGYTECH</h2>
                              <p>Energy Tech is a Visionary Project operating within the solar power generation industry. It offers comprehensive solutions to address the power requirements of Residential, Commercial, Industrial, and Agricultural sectors. This initiative is focused on harnessing the potential of solar energy to meet the wide range of power needs, promoting sustainability, and Environmental responsibility.</p>
                           </div>                           
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12">
                     <p>Octaverse actively involves the community in investing in the construction and maintenance of Photovoltaic (PV) power systems through Non-Fungible Tokens (NFTs). Participants can invest in NFTs representing ownership or stakes in these solar projects, thereby contributing to the expansion and upkeep of renewable energy infrastructure. By doing so, investors have the opportunity to earn rewards and achieve a Return on Investment (ROI) as these projects generate clean energy and revenue.</p>
                     <p>This innovative approach not only democratizes access to investments in renewable energy but also empowers individuals to participate in fostering environmental sustainability. Through community engagement via NFTs, Octaverse promotes a collaborative effort towards advancing solar power adoption and addressing global energy challenges, also paving the way for cleaner eco-friendly practices and a more sustainable future.</p>
                  </div>
               </div>
            </div>
            <Footer />
         </section>
      </div>
   )
}
export default Platform;