import { Link } from "react-router-dom";
import "../../../assets/scss/Footer/Footer.scss";
import telegramIcon from '../../../assets/images/telegramIcon.png';
import facebook from '../../../assets/icons/facebook.svg';
import telegram from '../../../assets/icons/telegram.svg';
import twitter from '../../../assets/icons/twitter.svg';
import instagram from '../../../assets/icons/instagram.svg';
import youtube from '../../../assets/icons/youtube.svg';

const Footer = () => {
   return (
         <footer>
            <div className="container">
               <div className="w-100 d-flex align-items-center flex-wrap footerTop">
                  <h3 className="secHeading mb-1 my-2 mr-2">Get Regular Update</h3>
                  <form className="ml-auto">
                     <div className="form-group d-flex flex-wrap flex-sm-nowrap align-items-center mb-0">
                        <input type="text" placeholder="Enter Your Email Address" className="form-control mr-2" />
                        <button className="btn KnwMrBtn Btn153-45">Subscribe</button>
                     </div>
                  </form>
               </div>
               <div className="w-100 my-3 my-lg-5">
                  <div className="row justify-content-xl-between">
                     <div className="col-lg-4">
                        <div className="joinCnt d-flex justify-content-center align-items-center">
                           <div className="text-center">
                              <img src={telegramIcon} width="55" height="55 " alt="telegramIcon" className="mb-3" />
                              <h3>Join Our Blockchain Community</h3>
                              <button className="btn">Join Now</button>
                           </div>
                        </div>
                     </div>
                     <div className="col-6 col-lg-3 col-xl-2">
                        <ul>
                           <li className="heading">Quick Links</li>
                           <li><Link to="/">Whitepaper</Link></li>
                           <li><Link to="/">One pager</Link></li>
                           <li><Link to="/">Presentation</Link></li>
                           <li><Link to="/">Contact</Link></li>
                           <li><Link to="/">Token sale</Link></li>
                        </ul>
                     </div>
                     <div className="col-6 col-lg-3 col-xl-2">
                        <ul>
                           <li className="heading">Others Links</li>
                           <li><Link to="/">About</Link></li>
                           <li><Link to="/">API Docs</Link></li>
                           <li><Link to="/">News</Link></li>
                           <li><Link to="/">Team</Link></li>
                           <li><Link to="/">FAQ</Link></li>
                        </ul>
                     </div>
                     <div className="col-6 col-lg-2 col-xl-2">
                        <ul>
                           <li className="heading">Legal</li>
                           <li><Link to="/">Privacy Policy</Link></li>
                           <li><Link to="/">Terms & Conditions</Link></li>
                           <li><Link to="/">Disclaimer</Link></li>
                           <li><Link to="/">Sitemap</Link></li>
                           <li><Link to="/">Terms & Use</Link></li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div className="w-100 d-flex flex-wrap align-items-center copyrightsCnt">
                  <p>Copyright © 2024 Octaverse – All Rights Reserved.</p>
                  <div className="d-flex align-items-center socialIconCnt ml-md-auto">
                     <Link to="/" className="socialIcon"><img src={facebook} width="14" height="14" alt="facebook" /></Link>
                     <Link to="/" className="socialIcon"><img src={telegram} width="14" height="14" alt="telegram" /></Link>
                     <Link to="/" className="socialIcon"><img src={twitter} width="14" height="14" alt="twitter" /></Link>
                     <Link to="/" className="socialIcon"><img src={youtube} width="14" height="14" alt="youtube" /></Link>
                     <Link to="/" className="socialIcon"><img src={instagram} width="14" height="14" alt="instagram" /></Link>
                  </div>
               </div>
            </div>
         </footer>
   )
}
export default Footer;